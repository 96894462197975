export class DripList {
  static readonly type = '[DripList] Post';
}

export class SaveDrip {
  static readonly type = '[SaveDrip] Post';
  constructor(public param: IDripList) {}
}

export class DeleteDripType {
  static readonly type = '[DeleteDripType] Post';
  constructor(public param: { id: number }) {}
}

export class DeleteDripFile {
  static readonly type = '[DeleteDripFile] Post';
  constructor(public param: IDeleteSettingsOnboardingFile) {}
}
export class updateDripList {
  static readonly type = '[updateDripList] Post';
  constructor(public param: IUpdateOrderSettingsDripParam) {}
}

