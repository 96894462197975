import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';
import {
  DeleteDripFile,
  DeleteDripType,
  DripList,
  SaveDrip,
  updateDripList,
} from '../actions/drip.action';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';

interface IDripStateModel {
  dripList: IDripList[];
  dripId: number;
}

@State<IDripStateModel>({
  name: 'DripState',
  defaults: {
    dripList: [],
    dripId: 0,
  },
})
@Injectable()
export class DripState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static dripList(state: IDripStateModel) {
    return state.dripList;
  }

  @Action(DripList)
  DripList(ctx: StateContext<IDripStateModel>) {
    return this._http.post('drip/list', {}).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          dripList: resultData.drip,
        });
      })
    );
  }

  @Action(SaveDrip)
  SaveDrip(ctx: StateContext<IDripStateModel>, { param }: SaveDrip) {
    return this._http.post('drip/add-edit', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          dripId: resultData.drip,
        });

        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
  @Action(updateDripList)
  updateDripList(
    ctx: StateContext<IDripStateModel>,
    { param } : updateDripList
  ) {
    return this._http.post('drip/update-order', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          dripList: resultData.drip,
        });
      })
    );
  }
  @Action(DeleteDripType)
  DeleteDripType(
    ctx: StateContext<IDripStateModel>,
    { param }: DeleteDripType
  ) {
    return this._http.post('drip/delete', param).pipe(
      tap((apiResult: any) => {

        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch({
            dripList: removeItem<IDripList>((item) => item.id === param.id),
          })
        );
      })
    );
  }

  @Action(DeleteDripFile)
  DeleteDripFile(
    ctx: StateContext<IDripStateModel>,
    { param }: DeleteDripFile
  ) {
    return this._http.post('file/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });

        ctx.setState(
          patch<IDripStateModel>({
            dripList: updateItem<IDripList>(
              (item) => item.id === param.id,
              patch({ drip_img: '', file_name: '' })
            ),
          })
        );
      })
    );
  }
}
